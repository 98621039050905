import { Controller } from "@hotwired/stimulus"
import { getSessionStorageWithConsent, setSessionStorageWithConsent } from "../utils/storage"
import { Consent } from "../utils/consent"

const CLOSED_STATE_KEY = "closed"
const OPEN_STATE_KEY = "open"

export default class extends Controller {
  static targets = ["panel", "button", "mobileButton"]
  static classes = ["buttonInactive", "buttonActive", "desktopHide", "mobileShow"]
  static values = { defaultBehavior: String }

  get DESKTOP_SESSION_STORAGE_KEY() {
    return "desktopFiltersPanelState"
  }

  get MOBILE_SESSION_STORAGE_KEY() {
    return "mobileFiltersPanelState"
  }

  connect() {
    // default to remembered state, then mobile defaults to closed & desktop defaults to default desktop state
    if (this.rememberedClosed()) {
      this.hideFilters()
    } else if (this.rememberedOpen()) {
      this.showFilters()
    } else {
      this.doDefault()
    }
  }

  doDefault() {
    if (this.closedByDefault()) {
      this.hideFilters()
    } else {
      this.showFilters()
    }
  }

  closedByDefault() {
    return this.isMobile() || this.defaultBehaviorValue === CLOSED_STATE_KEY
  }

  toggle() {
    if (this.isHidden()) {
      this.showFilters()
    } else {
      this.hideFilters()
    }
  }

  isHidden() {
    return this.isImplicitlyHidden() || this.isExplicitlyHidden()
  }

  isImplicitlyHidden() {
    return getComputedStyle(this.panelTarget).display === "none"
  }

  isExplicitlyHidden() {
    if (this.isMobile()) {
      return !this.panelTarget.classList.contains(this.mobileShowClass)
    } else {
      return this.panelTarget.classList.contains(this.desktopHideClass)
    }
  }

  showFilters() {
    if (this.isMobile()) {
      this.panelTarget.classList.add(this.mobileShowClass)
      this.panelTarget.classList.remove(this.desktopHideClass)
    } else {
      this.panelTarget.classList.remove(this.mobileShowClass)
      this.panelTarget.classList.remove(this.desktopHideClass)
    }

    this.rememberState(OPEN_STATE_KEY)
    this.buttonTarget.classList.remove(this.buttonInactiveClass)
    this.buttonTarget.classList.add(this.buttonActiveClass)
    this.setAnalyticsEventLabel("Close")
  }

  hideFilters() {
    if (this.isMobile()) {
      this.panelTarget.classList.remove(this.mobileShowClass)
      this.panelTarget.classList.remove(this.desktopHideClass)

      this.buttonTarget.classList.add(this.buttonActiveClass)
      this.buttonTarget.classList.remove(this.buttonInactiveClass)
    } else {
      this.panelTarget.classList.add(this.desktopHideClass)
      this.panelTarget.classList.remove(this.mobileShowClass)

      this.buttonTarget.classList.add(this.buttonInactiveClass)
      this.buttonTarget.classList.remove(this.buttonActiveClass)
    }
    this.rememberState(CLOSED_STATE_KEY)
    this.setAnalyticsEventLabel("Open")
  }

  rememberState(state) {
    setSessionStorageWithConsent(this.sessionStorageKey(), state, Consent.preferences)
  }

  toggleButtonIcons() {
    this.closeIconTarget.classList.toggle(this.iconHideClass)
    this.openIconTarget.classList.toggle(this.iconHideClass)
  }

  rememberedState() {
    return getSessionStorageWithConsent(this.sessionStorageKey(), Consent.preferences)
  }

  rememberedClosed() {
    return this.rememberedState() === CLOSED_STATE_KEY
  }

  rememberedOpen() {
    return this.rememberedState() === OPEN_STATE_KEY
  }

  sessionStorageKey() {
    if (this.isMobile()) {
      return this.MOBILE_SESSION_STORAGE_KEY
    } else {
      return this.DESKTOP_SESSION_STORAGE_KEY
    }
  }

  isMobile() {
    return getComputedStyle(this.panelTarget).position === "fixed"
  }

  setAnalyticsEventLabel(eventLabel) {
    let payload = JSON.parse(this.buttonTarget.dataset.analyticsEvent)
    payload = JSON.stringify({ ...payload, eventLabel })

    this.buttonTarget.dataset.analyticsEvent = payload
    this.mobileButtonTarget.dataset.analyticsEvent = payload
  }
}
