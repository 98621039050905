import { Controller } from "@hotwired/stimulus"
import { getCookieWithConsent, setCookieWithConsent } from "../utils/cookies"
import { Consent } from "../utils/consent"
import { getLocalStorageWithConsent, setLocalStorageWithConsent } from "../utils/storage"
import gaQueue from "../utils/ga_queue"

export default class extends Controller {
  static values = {
    url: String,
    data: Object,
    category: String,
  }

  static targets = ["css", "html"]

  static classes = ["dismiss"]

  connect() {
    this.pickAndDisplayBanner()
    this.reportBannerImpression()
  }

  click(event) {
    const clickedDismissOrChild = Boolean(event.target.closest(".js-banner__dismiss"))
    if (clickedDismissOrChild) {
      event.preventDefault()
      this.onDismiss()
    } else {
      this.reportBannerClick()
    }
  }

  onDismiss() {
    this.hideBanner()
    if (this.banner) {
      setLocalStorageWithConsent(this.getBannerCacheId(this.banner), "hidden", Consent.marketing)
    }
    this.reportBannerClose()
  }

  pickAndDisplayBanner() {
    this.banner = this.pickBanner()
    if (this.banner) {
      if (this.hasHtmlTarget) {
        this.htmlTarget.innerHTML = this.banner.html
      }
      this.cssTarget.innerHTML = this.banner.css
      this.showBanner()
    } else {
      this.hideBanner()
    }
  }

  hideBanner() {
    this.element.classList.remove(this.element.dataset.bannerShowClass)
  }

  showBanner() {
    this.element.classList.add(this.element.dataset.bannerShowClass)
  }

  pickBanner() {
    if (this.dataValue.banners && this.dataValue.banners.length > 0) {
      const pageKey = this.getPageKey()
      const banner = this.getPreviouslyPickedBanner(pageKey) || this.pickRandomBanner()
      const selectedBanner = this.isDismissed(banner) ? null : banner

      return selectedBanner
    }
    return null
  }

  getPageKey() {
    if (this.categoryBannersExist()) return this.categoryValue

    return "global"
  }

  globalBanners() {
    return this.dataValue.banners.filter((banner) => banner.categories === undefined || banner.categories.length === 0)
  }

  categoryBannersExist() {
    return this.dataValue.banners.some((banner) => {
      return banner.categories.some((category) => {
        return this.categoryValue.includes(category)
      })
    })
  }

  getPreviouslyPickedBanner(pageKey) {
    const previouslyPickedBannerId = this.getPreviouslyPickedBannerIds()[pageKey]

    return this.dataValue.banners.find((banner) => banner.id === previouslyPickedBannerId)
  }

  pickRandomBanner() {
    let filteredBanners
    let pageKey = this.categoryValue // This is '' on non category pages, so no category banners will be selected.
    // Find category specific banners first.
    filteredBanners = this.dataValue.banners.filter((banner) => {
      if (banner.categories.length === 0) return false

      return banner.categories.some((category) => {
        return pageKey.includes(category)
      })
    })

    if (filteredBanners.length === 0) {
      // If there are no category banners, use the global banners instead
      filteredBanners = this.globalBanners()
      pageKey = "global"
    }
    // Randomly pick a banner from the short list of banners
    const indexPicked = Math.floor(Math.random() * filteredBanners.length)
    const pickedBanner = filteredBanners[indexPicked]
    if (pickedBanner) {
      this.addCachedBannerId(pageKey, pickedBanner.id)
    }

    return pickedBanner
  }

  getPreviouslyPickedBannerIds() {
    const ids = getCookieWithConsent("marketplace_banners", Consent.marketing, JSON.stringify({}))
    return ids ? JSON.parse(ids) : {}
  }

  handleCookieBotConsent() {
    const noStoredBanners = Object.keys(this.getPreviouslyPickedBannerIds()).length === 0

    if (this.banner && noStoredBanners) this.addCachedBannerId(this.getPageKey(), this.banner.id)
  }

  addCachedBannerId(pageKey, bannerId) {
    setCookieWithConsent(
      "marketplace_banners",
      JSON.stringify({
        ...this.getPreviouslyPickedBannerIds(),
        [pageKey]: bannerId,
      }),
      Consent.marketing,
      {
        domain: `${window.location.host}`,
        expires: 14,
      },
    )
  }

  getBannerCacheId(banner) {
    if (banner) {
      return `banner_${banner.id}_cache`
    }
    return null
  }

  isDismissed(banner) {
    return getLocalStorageWithConsent(this.getBannerCacheId(banner), Consent.marketing) === "hidden"
  }

  reportBannerClick() {
    if (this.banner) {
      gaQueue("ec:addPromo", this.bannerPayload())
      gaQueue("ec:setAction", "promo_click")
      gaQueue("send", "event", "Internal Promotions", "click", {
        label: this.banner.id,
      })
    }
  }

  reportBannerClose() {
    if (this.banner) {
      gaQueue("ec:addPromo", this.bannerPayload())
      gaQueue("ec:setAction", "promo_click")
      gaQueue("send", "event", "Banner Impression", "close", {
        label: this.banner.id,
      })
    }
  }

  reportBannerImpression() {
    if (this.banner) {
      gaQueue("ec:addPromo", this.bannerPayload())
      gaQueue("send", "event", "Banner Impression", "view", {
        id: this.banner.id,
        label: this.banner.id,
      })
    }
  }

  bannerPayload() {
    return { id: this.banner.id, name: this.banner.id }
  }
}
