import { Controller } from "@hotwired/stimulus"
import CartEntrySummaryStore from "../stores/cart_entry_summary_store"
import ga from "../utils/ga"

export default class extends Controller {
  static values = {
    cartEntry: Object,
  }

  connect() {
    CartEntrySummaryStore.saveCartEntry(this.cartEntryValue)

    ga("send", {
      hitType: "pageview",
      title: "Modal: Item added to cart",
      page: `${window.location.pathname}/item-added-to-cart${window.location.search}`,
    })
  }
}
