import { Controller } from "@hotwired/stimulus"
import { FetchResponseError, handleError } from "../utils/errors"
import fetchWithCSRF from "../utils/fetch_with_csrf"
import { isSignedOut } from "../utils/session"

export default class extends Controller {
  static targets = ["fragment"]
  static values = {
    url: String,
    wait: Boolean,
    loaded: Boolean,
    sessionRequired: Boolean,
  }

  connect() {
    if (this.waitValue) return

    // we check the loadedValue here because we should still replace the signed in value
    // with the signed out default if it'd been previously loaded.
    if (!this.loadedValue && this.sessionRequiredValue && isSignedOut()) return

    this.load()
  }

  loadFromElement(e) {
    this.load(e.target.href || e.target.value)
    this.element.dataset.loading = "true"
    e.preventDefault()
  }

  load(url = this.urlValue) {
    if (this.abortController) {
      this.abortController.abort()
    }
    this.abortController = new AbortController()
    fetchWithCSRF(url, { signal: this.abortController.signal })
      .then((response) => this._responseTextIfOk(response))
      .then((html) => this._setContent(html))
      .catch((error) => handleError(error))
  }

  _setContent(html) {
    let targetArea = this.element

    if (this.hasFragmentTarget) {
      targetArea = this.fragmentTarget
    }

    targetArea.innerHTML = html
    this.loadedValue = true
    this.element.removeAttribute("data-loading")
  }

  _responseTextIfOk(response) {
    if (this.abortController) {
      delete this.abortController
    }
    if (response.ok) {
      return response.text()
    } else {
      throw new FetchResponseError(response)
    }
  }
}
