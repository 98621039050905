// Based off autocomplete from https://github.com/afcapel/stimulus-autocomplete
// Modified and simplified to suit our needs
//
// Usage:
//
// <form role="search" method="get" action="/search"
//   data-controller="autosuggest"
//   data-action="submit->autosuggest#onSubmit"
//   data-autosuggest-result-class="searchResultItem">
//
//     <input value=""
//       data-action="keydown->autosuggest#onKeydown blur->autosuggest#onInputBlur focus->autosuggest#onInputFocus input->autosuggest#onInputChange"
//       data-autosuggest-target="input">
//
//    <nav data-autosuggest-target="results"></nav>
//
//    <button type="submit">
//      Search
//    </button>
// </form>
//
// When an item is selected from autosuggest results, the parent form will be submitted

import { Controller } from "@hotwired/stimulus"
import debounce from "lodash/debounce"

import { getLocalStorageWithConsent, setLocalStorageWithConsent } from "../utils/storage"
import { throwUnlessOk, handleError } from "../utils/errors"
import { Consent } from "../utils/consent"
import fetchWithCSRF from "../utils/fetch_with_csrf"

const MAX_RECENT_TERMS = 5
const ALLOWED_SEARCH_CHARACTERS = /[a-zA-Z0-9&\-_.\s]/g

export default class extends Controller {
  static values = { category: String, suggestionsCount: Number }
  static targets = ["input", "results", "clear", "form", "submit", "category"]
  static classes = ["result", "resultCategory", "resultSeparator"]

  initialize() {
    this.onInputChange = debounce(this.onInputChange.bind(this), 300, { maxWait: 1000 })

    // Storefront can scope suggestions by category by passing in a `category` param.
    // Shopfront does not using this functionality on autosuggestion. Rather it always requests 'standard' suggestions.
    // 'Standard' suggestions include 3 suggestions with a category provide, and 5 general ones.
    // In theory, the server side scoping is preferable as the suggestions are made within the context of the category.
    // However, at the time of writing, we want to ensure like for like behaviour.
    this.useServerCategoryScoping = false
  }

  connect() {
    this.inputTarget.value = this.inputTarget.dataset.searchTerm
    this.recentSearchTerms = getLocalStorageWithConsent("recentSearch", Consent.preferences) || []
    this.revision = document.querySelector("meta[name='revision']").getAttribute("content")
    this.resultsTarget.hidden = true
    this.mouseDown = false
    this.inputTarget.setAttribute("autocomplete", "off")
    this.inputTarget.setAttribute("spellcheck", "false")
    this.submitTarget.disabled = false
    this.toggleClearButton()
  }

  getNextOption() {
    const options = Array.from(this.resultsTarget.querySelectorAll('[role="option"]'))
    const selected = this.resultsTarget.querySelector('[aria-selected="true"]')
    const index = options.indexOf(selected)
    return options[index + 1] || options[0]
  }

  getPreviousOption() {
    const options = Array.from(this.resultsTarget.querySelectorAll('[role="option"]'))
    const selected = this.resultsTarget.querySelector('[aria-selected="true"]')
    const index = options.indexOf(selected)
    return options[index - 1] || options[options.length - 1]
  }

  rememberSearchTerm() {
    const term = this.getSearchInputValue()
    if (term !== "") {
      this.addRecentSearchTerm(term)
    }
  }

  select(target) {
    for (const el of this.resultsTarget.querySelectorAll('[aria-selected="true"]')) {
      el.removeAttribute("aria-selected")
      el.removeAttribute("active")
    }
    target.setAttribute("aria-selected", "true")
    target.setAttribute("active", "true")
    this.inputTarget.setAttribute("aria-activedescendant", target.id)
  }

  addHTMLSeparator(suggestionsHTML) {
    const suggestionsToSeparate = suggestionsHTML.filter((s) => s.includes(" in "))
    if (suggestionsToSeparate.length > 0) {
      suggestionsHTML.splice(suggestionsToSeparate.length, 0, `<div class="${this.resultSeparatorClass}"></div>`)
    }
  }

  suggestionsToHTML(query, suggestions) {
    let filteredSuggestions = suggestions
    if (!this.useServerCategoryScoping && this.hasCategoryValue && this.categoryValue !== "") {
      // If we use server side category scoping, the suggestions are already scoped and we don't need to filter
      // If we are not using server side category scoping AND we are inside of a category on the client
      // then we only want to keep suggestions that do not have a category path specified
      filteredSuggestions = suggestions.filter((suggestion) => suggestion.category_path === null)
    }

    const suggestionsHTML = filteredSuggestions
      .slice(0, this.suggestionsCountValue)
      .map((s) => this.HTMLForSearchSuggestion(query, s.term, s.category_path, s.category_name))

    if (suggestionsHTML.length > 0) {
      this.addHTMLSeparator(suggestionsHTML)
    }
    this.appendSuggestionsHTML(suggestionsHTML)
  }

  recentTermsToHTML(suggestions) {
    const suggestionsHTML = suggestions.map((s) => this.HTMLForSearchSuggestion("", s, ""))
    this.appendSuggestionsHTML(suggestionsHTML)
  }

  appendSuggestionsHTML(suggestionsHTML) {
    if (suggestionsHTML.length > 0) {
      this.resultsTarget.innerHTML = suggestionsHTML.join("")
      this.open()
    } else {
      this.close()
    }
  }

  showSuggestions() {
    const query = this.getSearchInputValue()

    if (query && this.suggestionsCountValue > 0) {
      const suggestionsPath = `/storefront/search_suggestions`

      let url = `${suggestionsPath}?query=${query}&revision=${this.revision}`
      if (this.useServerCategoryScoping) {
        url = `${suggestionsPath}?category=${this.categoryValue}&query=${query}&revision=${this.revision}`
      }

      fetchWithCSRF(url)
        .then(throwUnlessOk)
        .then((response) => response.json())
        .then((data) => this.suggestionsToHTML(query, data))
        .catch((error) => handleError(error))
    } else {
      this.recentTermsToHTML(this.recentSearchTerms)
    }
  }

  HTMLForSearchSuggestion(query, term, categoryPath, categoryName) {
    const eventData = JSON.stringify({
      hitType: "event",
      eventCategory: "Block interaction; Search",
      eventAction: "click;button",
      eventLabel: "autosuggest",
    })

    query = query.toLowerCase()
    const prefix = term.startsWith(query) ? query : ""
    const suffix = term.startsWith(query) ? term.substring(prefix.length, term.length) : term
    const fallbackCategoryName = categoryName || categoryPath
    const suffixWithCategory = categoryPath
      ? `${suffix}<span class="${this.resultCategoryClass}"> in ${fallbackCategoryName}</span>`
      : suffix

    return `<div role="option"
                data-autocomplete-value="${term}"
                data-autocomplete-category="${categoryPath || ""}"
                data-action="click->analytics-event#send click->autosuggest#onResultsClick mousedown->autosuggest#onResultsMouseDown"
                data-analytics-event="${eventData.replace(/"/g, "&quot;")}"
                class="${this.resultClass}"><b>${prefix}</b>${suffixWithCategory}</div>`
  }

  addRecentSearchTerm(term) {
    if (!term) return

    if (this.recentSearchTerms.includes(term)) {
      this.recentSearchTerms = this.recentSearchTerms.filter((t) => t !== term)
    } else if (this.recentSearchTerms.length >= MAX_RECENT_TERMS) {
      this.recentSearchTerms.pop()
    }

    this.recentSearchTerms.unshift(term)
    setLocalStorageWithConsent("recentSearch", JSON.stringify(this.recentSearchTerms), Consent.preferences)
  }

  onKeydown(event) {
    switch (event.key) {
      case "Escape":
        if (!this.resultsTarget.hidden) {
          this.close()
          event.stopPropagation()
          event.preventDefault()
        }
        break
      case "ArrowDown":
        {
          const item = this.getNextOption()
          if (item) this.select(item)
          event.preventDefault()
        }
        break
      case "ArrowUp":
        {
          const item = this.getPreviousOption()
          if (item) this.select(item)
          event.preventDefault()
        }
        break
      case "Tab":
        {
          const selected = this.resultsTarget.querySelector('[aria-selected="true"]')
          if (selected) {
            this.commit(selected)
          }
        }
        break
      case "Enter":
        {
          const selected = this.resultsTarget.querySelector('[aria-selected="true"]')
          if (selected && !this.resultsTarget.hidden) {
            event.preventDefault() // Enter submits a form, and does this.commit(), so we prevent 1 of them
            this.commit(selected)
          }
        }
        break
    }
  }

  onInputFocus() {
    this.showSuggestions()
  }

  onInputBlur() {
    if (this.mouseDown) return
    this.close()
  }

  onSubmit() {
    this.rememberSearchTerm()
    this.submitTarget.disabled = true
  }

  commit(selected) {
    const term = selected.getAttribute("data-autocomplete-value")
    const category = selected.getAttribute("data-autocomplete-category") || this.categoryValue

    this.categoryTarget.setAttribute("value", category)

    this.inputTarget.value = term
    this.close()

    this.submitTarget.click()
  }

  clearSearchInput() {
    this.inputTarget.value = ""
    this.toggleClearButton()
  }

  toggleClearButton() {
    if (this.hasClearTarget) {
      this.clearTarget.dataset.visible = this.getSearchInputValue() ? "true" : "false"
    }
  }

  onResultsClick(event) {
    if (!(event.target instanceof Element)) return
    const selected = event.target.closest('[role="option"]')
    if (selected) this.commit(selected)
  }

  onResultsMouseDown() {
    this.mouseDown = true
    this.resultsTarget.addEventListener("mouseup", () => (this.mouseDown = false), { once: true })
  }

  getSearchInputValue() {
    const value = this.inputTarget.value.trim()
    return (value.match(ALLOWED_SEARCH_CHARACTERS) || []).join("")
  }

  onInputChange() {
    this.toggleClearButton()
    this.showSuggestions()
  }

  open() {
    if (!this.resultsTarget.hidden) return
    if (this.resultsTarget.querySelector('[role="option"]')) {
      this.resultsTarget.hidden = false
      this.element.setAttribute("aria-expanded", "true")
      this.element.setAttribute("active", true)
    }
  }

  close() {
    if (this.resultsTarget.hidden) return
    this.resultsTarget.hidden = true
    this.inputTarget.removeAttribute("aria-activedescendant")
    this.element.removeAttribute("active")
    this.element.setAttribute("aria-expanded", "false")
    this.resultsTarget.innerHTML = null
  }

  get src() {
    return this.data.get("url")
  }
}
