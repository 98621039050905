// This controller facilitates sending custom events to Google Analytics as a
// result of user interactions with the page.
//
// Usage:
//
// <body data-controller="analytics-event">
//   <a href="..."
//      data-action="analytics-event#send"
//      data-analytics-event="<%= {someKey: "some_value"}.to_json %>"
//   >
//     Hello World
//   </a>
// </body>

import { Controller } from "@hotwired/stimulus"
import ga from "../utils/ga"
import { sendStandardEvent } from "../utils/google_analytics"
import gaQueue from "../utils/ga_queue"

export default class extends Controller {
  static targets = ["immediate"]

  connect() {
    this.immediateTargets.forEach((element) => {
      // Send events via a GA queue, so they're fired after the page view event.
      this.sendQueuedEvents(this.extractPayloadFromElement(element))
    })
  }

  send(event) {
    this.sendEvents(this.extractPayloadFromElement(event.target))
  }

  sendAlt(event) {
    this.sendEvents(this.extractPayloadFromElement(event.target, { alt: true }))
  }

  sendEditCartEvent(event) {
    const gaCustomEventData = this.extractPayloadFromElement(event.target)
    const page = "/search/edit-cart-entry" + window.location.search
    ga("send", { ...gaCustomEventData, page })
  }

  sendFilterPanelEvent(event) {
    const gaCustomEventData = this.extractPayloadFromElement(event.target)
    const ariaLabel = event.target.closest("[aria-label]").getAttribute("aria-label")
    this.sendEvents({
      ...gaCustomEventData,
      eventCategory: ariaLabel.includes("Expand") ? "FilterExpand" : "FilterCollapse",
    })
  }

  sendMiniCartEvent(event) {
    const gaCustomEventData = this.extractPayloadFromElement(event.target)
    ga("send", { ...gaCustomEventData, eventCategory: "page-header-top" })
  }

  sendEventBySelect(event) {
    const gaCustomEventData = this.extractPayloadFromElement(event.target)
    const optionSelectedLabel = event.currentTarget.selectedOptions[0].dataset.analyticsLabel
    this.sendEvents({
      ...gaCustomEventData,
      eventLabel: optionSelectedLabel,
    })
  }

  trackClick(event) {
    // This is similar to the standardised tracking in Elements and Marketplace.
    // As detailed here: https://github.com/envato/standard-analytics
    // We take a global "page id", a "section id" and an "element id" and use that to build the event payload.

    const pageId = document.body.dataset.pageType
    if (!pageId) return

    const target = event.target.closest("[data-analytics-element-id]")
    if (!target) return

    const elementId = target.dataset.analyticsElementId
    const elementLabel = target.dataset.analyticsElementLabel
    const section = target.closest("[data-analytics-section-id]")
    if (!section) return

    const sectionId = section.dataset.analyticsSectionId
    const tagName = target.nodeName?.toLowerCase()

    if (sectionId && elementId && tagName) {
      sendStandardEvent({
        eventType: "click",
        pageId,
        sectionId,
        elementId,
        elementLabel,
        tagName,
      })
    }
  }

  trackSubmit(event) {
    // This is similar to the standardised tracking in Elements and Marketplace.
    // As detailed here: https://github.com/envato/standard-analytics
    // We take a global "page id", a "section id" and an "element id" and use that to build the event payload.
    // We take all the user input form values and include those in the event label.

    const pageId = document.body.dataset.pageType
    if (!pageId) return

    const target = event.target.closest("[data-analytics-form-id]")
    if (!target) return

    const elementId = target.dataset.analyticsFormId
    const section = target.closest("[data-analytics-section-id]")
    if (!section) return

    // Build up a label based on the form elements
    // <input type="text" name="foo" value="bar"> results in a "foo=bar" label
    const elementLabels = []
    const formElementsForLabel = target.querySelectorAll("input[type=text],input[type=textarea],input[type=select]")
    formElementsForLabel.forEach((element) => {
      const elementName = element.dataset.analyticsLabelId || element.name
      elementLabels.push(`${elementName}=${element.value}`)
    })
    const elementLabel = elementLabels.join(";")

    const sectionId = section.dataset.analyticsSectionId

    if (sectionId && elementId) {
      sendStandardEvent({
        eventType: "submit",
        pageId,
        sectionId,
        elementId,
        elementLabel,
        tagName: "form",
      })
    }
  }

  // private

  sendEvents(payload) {
    // TODO: @arman a simple refactor will help avoid this conditional. I will
    //       come back to do this once done with bringing over all the events
    payload = Array.isArray(payload) ? payload : [["send", payload]]
    payload.forEach((entry) => ga(...entry))
  }

  sendQueuedEvents(payload) {
    // TODO: @arman a simple refactor will help avoid this conditional. I will
    //       come back to do this once done with bringing over all the events
    payload = Array.isArray(payload) ? payload : [["send", payload]]
    payload.forEach((entry) => gaQueue(...entry))
  }

  extractPayloadFromElement(el, options = {}) {
    let nodeWithCustomData, rawData

    if (options.alt) {
      nodeWithCustomData = el.closest("[data-analytics-event-alt]")
      rawData = nodeWithCustomData.dataset.analyticsEventAlt
    } else {
      nodeWithCustomData = el.closest("[data-analytics-event]")
      rawData = nodeWithCustomData.dataset.analyticsEvent
    }
    return JSON.parse(rawData)
  }
}
