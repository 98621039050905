import { Consent, deferRun } from "./consent"
import { loadScript } from "./load_script"

// If there is already marketing consent, we run the script
// straight away, otherwise we wait for a consent event
// that includes marketing consent
export const deferLoadGTMScript = () => {
  const loadMarketingGTMWithData = () => {
    const w = window
    w.dataLayer = w.dataLayer || []
    w.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    })
    loadScript("https://www.googletagmanager.com/gtm.js?id=GTM-585RXMV")
  }

  const loadStatisticsGTMWithData = () => {
    const w = window
    w.dataLayer = w.dataLayer || []
    w.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    })
    loadScript("https://www.googletagmanager.com/gtm.js?id=GTM-W8KL5Q5")
  }

  deferRun(loadMarketingGTMWithData, Consent.marketing)

  if (window.gtmEnvironment === "staging" || window.gtmEnvironment === "development") {
    deferRun(loadStatisticsGTMWithData, Consent.statistics)
  }
}
