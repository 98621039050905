import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["imageInformation"]

  connect() {
    this.updateAnalyticsData()
  }

  updateAnalyticsData() {
    const analyticsEvent = this.imageInformationTarget.dataset.analyticsEvent
    const impressionPosition =
      this.imageInformationTarget.closest("[class^='item-']").firstElementChild.dataset.impressionPosition
    const impressionList =
      this.imageInformationTarget.closest("[class^='item-']").firstElementChild.dataset.impressionList
    this.imageInformationTarget.dataset.analyticsEvent = analyticsEvent
      .replace("%{ga_impression_position}", impressionPosition)
      .replace("%{ga_impression_list}", impressionList)
  }
}
