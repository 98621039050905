import { Controller } from "@hotwired/stimulus"
import gaQueue from "../utils/ga_queue"

export default class extends Controller {
  static get targets() {
    return ["item"]
  }

  static values = {
    additionalParams: Object,
    eventCategory: { type: String, default: "Ecommerce" },
  }

  connect() {
    if (this.itemTargets.length > 0) {
      this.itemTargets.forEach((item) => this.trackImpression(item))

      gaQueue("send", "event", this.eventCategoryValue, "view", "Product impression", {
        nonInteraction: true,
        ...this.additionalParamsValue,
      })
    }
  }

  trackImpression(item) {
    gaQueue("ec:addImpression", this.impressionData(item))
  }

  impressionData(item) {
    // id of Elements item contains capital letters
    // whereas Market item id only has digits
    const hasAlpha = item.dataset.itemId.match(/[A-Z]/)
    const id = hasAlpha ? item.dataset.itemId : parseInt(item.dataset.itemId)

    const impressionData = {
      id,
      position: parseInt(item.dataset.impressionPosition),
      name: item.dataset.impressionName,
      list: item.dataset.impressionList,
      brand: item.dataset.impressionBrand,
    }

    const impressionCategory = item.dataset.impressionCategory
    if (impressionCategory) {
      impressionData.category = impressionCategory
    }

    return impressionData
  }
}
